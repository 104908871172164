import React from "react";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Article from "../components/article";

export default ({ data }) => {
  const subtitle =
    "An educational game developed for the Canadian Space and Aviation Museum" +
    " to spark children interest in space travel and learn more about the" +
    " International Space Station";

  return (
    <Layout indexPage={false}>
      <SEO title="Spacejam" keywords={["spacejam", "ux"]} />
      <Article
        title="Space Jam"
        subtitle={subtitle}
        imgSrc={data.spaceJam.publicURL}
        imgAlt="SpaceJam"
      >
        <div className="mt-8" />
        <p>
          As a group, we were asked by the Canadian Aviation and Space Museum to
          design and develop an educational game for children to help them learn
          more about astronauts and space travel.
        </p>
        <br />
        <p>
          I was the user experience designer and researcher for this project
          were I had to research the problem and come up with a solution and
          measure the effectiveness of that solution. Solution and steps: After
          researching the problem, we decided to focus on astronaut physical
          health and space flight nutrition. The game presented the journey and
          potential obstacles faced by a spaceship taking off from Earth to the
          International Space Station. The player had to avoid the obstacles and
          collect the right food that will help them survive the journey to
          their destination.
        </p>
        <br />
        <p>
          To measure the effectiveness and usability of the game, we did a focus
          group where we had children play the game and measured the difference
          in their knowledge in the subject before and after playing the game.
          Finally, we used the data collected to improve on the game before
          presenting it to the Canadian Aviation and Space Museum.
        </p>
      </Article>
    </Layout>
  );
};

export const query = graphql`
  query {
    spaceJam: file(relativePath: { eq: "space_jam.jpg" }) {
      publicURL
    }
  }
`;
