import React from "react";
import BottomNav from "../components/bottom-nav";

function Article({ title, subtitle, imgSrc, imgAlt, children }) {
  return (
    <div className="flex flex-col mt-10 leading-loose mx-4">
      <h1 className="font-serif md:text-6xl text-center text-blue">{title}</h1>
      <p className="max-w-md mx-auto text-center font-medium">{subtitle}</p>
      <div className="max-w-md mx-auto mt-6 leading-huge font-light text-grey-darker">
        <img className="my-4" src={imgSrc} alt={imgAlt} />
        {children}
      </div>
      <div className="mb-8 mb:mb-32" />
      <div className="mx-auto">
        <BottomNav />
      </div>
    </div>
  );
}

export default Article;
